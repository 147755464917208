import React, { useEffect, useState } from "react";
import Toast from "./../LoadingError/Toast";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editCar, updateCar } from "../../Redux/Actions/CarActions";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import { CAR_UPDATE_RESET } from "../../Redux/Constants/CarConstants";

const EditProductMain = (props) => {
  const { carId } = props;

  const [registration, setregistration] = useState("")
  const [vin, setvin] = useState("")
  const [car_name, setcar_name] = useState("")
  const [brand, setbrand] = useState("")
  const [model, setmodel] = useState("")
  const [fuel, setfuel] = useState("")
  const [segment, setsegment] = useState("")
  const [transmition, settransmition] = useState("")
  const [year_origin, setyear_origin] = useState("")
  const [month_origin, setmonth_origin] = useState("")
  const [kilometers, setkilometers] = useState(0)
  const [displacement, setdisplacement] = useState(0)
  const [price, setprice] = useState(0)
  const [car_cost, setcar_cost] = useState(0)
  const [power, setpower] = useState(0)
  const [ports, setports] = useState(0)
  const [provider, setprovider] = useState("")
  const [color, setcolor] = useState("")
  const [warranty, setwarranty] = useState("")
  const [stand_location, setstand_location] = useState("")
  const [first_image, setfirst_image] = useState("")
  const [images, setimages] = useState("")
  const [security_preformance, setsecurity_preformance] = useState("")
  const [inside_material, setinside_material] = useState("")
  const [outside_material, setoutside_material] = useState("")
  const [multimedia_confort, setmultimedia_confort] = useState("")
  const [is_active, setis_active] = useState(true)
  const [is_sold, setis_sold] = useState(false)

  const dispatch = useDispatch()

  const carEdit = useSelector((state) => state.carEdit)
  const {loading, error, car} = carEdit

  const carUpdate = useSelector((state) => state.carUpdate)
  const {loading:loadingUpdate, error:errorUpdate, car:successUpdate} = carUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({type: CAR_UPDATE_RESET})
    }else{
      if (!car.registration || car._id !== carId) {
        dispatch(editCar(carId))
  
      }else {
        setregistration(car.registration)
        setvin(car.vin)
        setcar_name(car.car_name)
        setmodel(car.model)
        setbrand(car.brand)
        setfuel(car.fuel)
        setsegment(car.segment)
        settransmition(car.transmition)
        setyear_origin(car.year_origin)
        setmonth_origin(car.month_origin)
        setkilometers(car.kilometers)
        setdisplacement(car.displacement)
        setprice(car.price)
        setcar_cost(car.car_cost)
        setpower(car.power)
        setports(car.ports)
        setprovider(car.provider)
        setcolor(car.color)
        setwarranty(car.warranty)
        setstand_location(car.stand_location)
        setfirst_image(car.first_image)
        setimages(car.images)
        setsecurity_preformance(car.security_preformance)
        setinside_material(car.inside_material)
        setoutside_material(car.outside_material)
        setmultimedia_confort(car.multimedia_confort)
        setis_active(car.is_active)
        setis_sold(car.is_sold)
      }
    }
  },[car, dispatch, carId, carUpdate]);

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(updateCar({_id: carId, registration, vin,car_name,  brand, model, fuel, segment, transmition, year_origin, 
      month_origin, kilometers, displacement, price, car_cost, power,
      ports, provider, color, warranty, stand_location,
      first_image, images, security_preformance, inside_material,
      outside_material, multimedia_confort, is_active, is_sold}))
  }

  return (
    <>
    <Toast/>
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={submitHandler}>
          <div className="content-header">
            <Link to="/" className="btn btn-danger text-white">
              Ir para Carros
            </Link>
            <h2 className="content-title">Alterar Carro</h2>
            <div>
              <button type="submit" className="btn btn-primary">
                Publicar Alterações
              </button>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                {errorUpdate && <Message variant="alert-danger">{errorUpdate}</Message>}
                {loadingUpdate && <Loading/>}
                {
                  loading ? <Loading/> : error ? <Message variant="alert-danger">{error}</Message> :
                  (
                    <>
                    <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Matricula
                    </label>
                    <input
                      type="text"
                      placeholder="Matricula do Carro"
                      className="form-control"
                      id="product_title"
                      required
                      value={registration}
                      onChange={(e) => setregistration(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Vin
                    </label>
                    <input
                      type="text"
                      placeholder="Vin do Carro"
                      className="form-control"
                      id="product_title"
                      required
                      value={vin}
                      onChange={(e) => setvin(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Nome do Carro
                    </label>
                    <input
                      type="text"
                      placeholder="Nome do Carro (definir nomes com alguma info, por exemplo: Mercedes Benz E 350 CDi Avantgarde BlueEfficiency Auto)"
                      className="form-control"
                      id="product_title"
                      required
                      value={car_name}
                      onChange={(e) => setcar_name(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Marca do Carro
                    </label>
                    <input
                      type="text"
                      placeholder="Marca do Carro"
                      className="form-control"
                      id="product_title"
                      required
                      value={brand}
                      onChange={(e) => setbrand(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Modelo do Carro
                    </label>
                    <input
                      type="text"
                      placeholder="Modelo do Carro"
                      className="form-control"
                      id="product_title"
                      required
                      value={model}
                      onChange={(e) => setmodel(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Combustível
                    </label>
                    <input
                      type="text"
                      placeholder="Combustível do Carro"
                      className="form-control"
                      id="product_title"
                      required
                      value={fuel}
                      onChange={(e) => setfuel(e.target.value)}
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Segmento
                    </label>
                    <input
                      type="text"
                      placeholder="Segmento do Carro (exemplo: Utilitário)"
                      className="form-control"
                      id="product_title"
                      required
                      value={segment}
                      onChange={(e) => setsegment(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Transmissão
                    </label>
                    <input
                      type="text"
                      placeholder="Transmissão do Carro (exemplo: Manual)"
                      className="form-control"
                      id="product_title"
                      required
                      value={transmition}
                      onChange={(e) => settransmition(e.target.value)}
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Ano de Origem
                    </label>
                    <input
                      type="text"
                      placeholder="2020"
                      className="form-control"
                      id="product_title"
                      required
                      value={year_origin}
                      onChange={(e) => setyear_origin(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Mês de Origem
                    </label>
                    <input
                      type="text"
                      placeholder="03"
                      className="form-control"
                      id="product_title"
                      required
                      value={month_origin}
                      onChange={(e) => setmonth_origin(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Numero de Quilômetros
                    </label>
                    <input
                      type="number"
                      placeholder="Kilometros do Carro"
                      className="form-control"
                      id="product_title"
                      required
                      value={kilometers}
                      onChange={(e) => setkilometers(e.target.value)}
                    />
                  </div> 
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Cilindrada
                    </label>
                    <input
                      type="number"
                      placeholder="Cilindrada"
                      className="form-control"
                      id="product_title"
                      required
                      value={displacement}
                      onChange={(e) => setdisplacement(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Preço
                    </label>
                    <input
                      type="number"
                      placeholder="Preço de Venda"
                      className="form-control"
                      id="product_title"
                      required
                      value={price}
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Custo do Carro
                    </label>
                    <input
                      type="number"
                      placeholder="Custo do Carro"
                      className="form-control"
                      id="product_title"
                      required
                      value={car_cost}
                      onChange={(e) => setcar_cost(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Número de Cavalos
                    </label>
                    <input
                      type="number"
                      placeholder="Número de Cavalos"
                      className="form-control"
                      id="product_title"
                      required
                      value={power}
                      onChange={(e) => setpower(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Número de Portas
                    </label>
                    <input
                      type="text"
                      placeholder="Número de Portas"
                      className="form-control"
                      id="product_title"
                      required
                      value={ports}
                      onChange={(e) => setports(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Fornecedor
                    </label>
                    <input
                      type="text"
                      placeholder="Fornecedor do Carro"
                      className="form-control"
                      id="product_title"
                      required
                      value={provider}
                      onChange={(e) => setprovider(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Cor do Carro
                    </label>
                    <input
                      type="text"
                      placeholder="Azul"
                      className="form-control"
                      id="product_title"
                      required
                      value={color}
                      onChange={(e) => setcolor(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Garantia
                    </label>
                    <input
                      type="text"
                      placeholder="18 meses"
                      className="form-control"
                      id="product_title"
                      required
                      value={warranty}
                      onChange={(e) => setwarranty(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                    Localização do Stand
                    </label>
                    <input
                      type="text"
                      placeholder="Localização do Stand"
                      className="form-control"
                      id="product_title"
                      required
                      value={stand_location}
                      onChange={(e) => setstand_location(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Imagem de Perfil
                    </label>
                    <input
                      type="text"
                      placeholder="url da imagem"
                      className="form-control"
                      id="product_title"
                      required
                      value={first_image}
                      onChange={(e) => setfirst_image(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                  <label htmlFor="list_input" className="form-label">
                    Lista de Imagens:
                  </label>
                  <textarea
                    id="list_input"
                    required
                    className="form-control"
                    placeholder="Coloca cada url separado por virgula"
                    value={images}
                    onChange={(e) => setimages(e.target.value)}
                  ></textarea>
                  </div>
                  <div className="mb-4">
                  <label htmlFor="list_input" className="form-label">
                    Lista de Segurança e Performance:
                  </label>
                  <textarea
                    id="list_input"
                    required
                    className="form-control"
                    placeholder="Coloca cada elemento separado por virgula"
                    value={security_preformance}
                    onChange={(e) => setsecurity_preformance(e.target.value)}
                  ></textarea>
                  </div>
                  <div className="mb-4">
                  <label htmlFor="list_input" className="form-label">
                    Lista de Material Interior:
                  </label>
                  <textarea
                    id="list_input"
                    required
                    className="form-control"
                    placeholder="Coloca cada elemento separado por virgula"
                    value={inside_material}
                    onChange={(e) => setinside_material(e.target.value)}
                  ></textarea>
                  </div>
                  <div className="mb-4">
                  <label htmlFor="list_input" className="form-label">
                    Lista de Material Exterior:
                  </label>
                  <textarea
                    id="list_input"
                    required
                    className="form-control"
                    placeholder="Coloca cada elemento separado por virgula"
                    value={outside_material}
                    onChange={(e) => setoutside_material(e.target.value)}
                  ></textarea>
                  </div>
                  <div className="mb-4">
                  <label htmlFor="list_input" className="form-label">
                    Lista de Conforto e Multimédia:
                  </label>
                  <textarea
                    id="list_input"
                    required
                    className="form-control"
                    placeholder="Coloca cada elemento separado por virgula"
                    value={multimedia_confort}
                    onChange={(e) => setmultimedia_confort(e.target.value)}
                  ></textarea>
                  </div>
                  <div className="mb-4">
                  <label htmlFor="boolean_input" className="form-label">
                    O carro está ativo no Site ? :
                  </label>
                  <select
                    id="boolean_input"
                    required
                    className="form-control"
                    value={is_active}
                    onChange={(e) => setis_active(e.target.value)}
                  >
                    <option value={true}>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label htmlFor="boolean_input" className="form-label">
                    O carro foi vendido ?:
                  </label>
                  <select
                    id="boolean_input"
                    required
                    className="form-control"
                    value={is_sold}
                    onChange={(e) => setis_sold(e.target.value)}
                  >
                    <option value={true}>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </div>
                    </>
                  )
                }

                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default EditProductMain;
