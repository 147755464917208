import { LEAD_LIST_FAIL, LEAD_LIST_REQUEST, LEAD_LIST_SUCCESS } from "../Constants/LeadConstants";


// ALL LEADS
export const leadListReducer = (state = { leads: [] }, action) => {
    switch (action.type) {
      case LEAD_LIST_REQUEST:
        return { loading: true, leads:[] };
      case LEAD_LIST_SUCCESS:
        return { loading: false, leads: action.payload };
      case LEAD_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };