import axios from "axios";
import { toast } from "react-toastify";
import { CAR_CREATE_FAIL, CAR_CREATE_REQUEST, CAR_CREATE_SUCCESS, CAR_EDIT_FAIL, CAR_EDIT_REQUEST, CAR_EDIT_SUCCESS, CAR_LIST_FAIL, CAR_LIST_REQUEST, CAR_LIST_SUCCESS, CAR_UPDATE_FAIL, CAR_UPDATE_REQUEST, CAR_UPDATE_SUCCESS } from "../Constants/CarConstants";
import { logout } from "./userActions";
import { URL } from "../Url";


export const listCars = () => async (dispatch, getState) => {
    try {
      dispatch({ type: CAR_LIST_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`${URL}/api/cars/all`, config);
  
      dispatch({ type: CAR_LIST_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: CAR_LIST_FAIL,
        payload: message,
      });
    }
  };

  //CREATE CAR 
  
export const createCar =
( registration, vin,car_name,  brand, model, fuel, segment, transmition, year_origin, 
  month_origin, kilometers, displacement, price, car_cost, power,
  ports, provider, color, warranty, stand_location,
  first_image, images, security_preformance, inside_material,
  outside_material, multimedia_confort, is_active, is_sold ) =>
async (dispatch, getState) => {

  const ToastObjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 5000,
  };

  try {
    dispatch({ type: CAR_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${URL}/api/cars/`,
      { registration, vin,car_name,  brand, model, fuel, segment, transmition, year_origin, 
        month_origin, kilometers, displacement, price, car_cost, power,
        ports, provider, color, warranty, stand_location,
        first_image, images, security_preformance, inside_material,
        outside_material, multimedia_confort, is_active, is_sold },
      config
    );

    dispatch({ type: CAR_CREATE_SUCCESS, payload: data });
    toast.success("Carro foi inserido com Sucesso", ToastObjects);

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: CAR_CREATE_FAIL,
      payload: message,
    });
    toast.warning("O Carro não foi inserido", ToastObjects);
  }
};

// EDIT CAR
export const editCar = (id) => async (dispatch, getState) => {
  try {
    dispatch({type: CAR_EDIT_REQUEST});

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
  
  const {data} = await axios.get(`${URL}/api/cars/all/${id}`, config);
  dispatch({type: CAR_EDIT_SUCCESS, payload:data});
} catch (error) {
  const message =
    error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  if (message === "Not authorized, token failed") {
    dispatch(logout());
  }
  dispatch({
    type: CAR_EDIT_FAIL,
    payload: message,
  });

} };

  //UPDATE CAR 
  
  export const updateCar =
  ( car ) =>
  async (dispatch, getState) => {
  
    const ToastObjects = {
      pauseOnFocusLoss: false,
      draggable: false,
      pauseOnHover: false,
      autoClose: 5000,
    };
  
    try {
      dispatch({ type: CAR_UPDATE_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.put(
        `${URL}/api/cars/${car._id}`,
        { car },
        config
      );
  
      dispatch({ type: CAR_UPDATE_SUCCESS, payload: data });
      dispatch({type: CAR_EDIT_SUCCESS, payload:data});
      toast.success("Carro foi atualizado com Sucesso", ToastObjects);
  
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: CAR_UPDATE_FAIL,
        payload: message,
      });
      toast.warning("O Carro não foi atualizado", ToastObjects);
    }
  };