import React from "react";
import { Link } from "react-router-dom";

const Product = (props) => {
  const { car } = props;

  return (
    <>
      <div className="col-md-6 col-sm-6 col-lg-3 mb-5">
        <div className="card card-product-grid shadow-sm">
          <Link to="#" className="img-wrap">
            <img src={ car && car.first_image ? car.first_image : null} alt="Product" />
          </Link>
          <div className="info-wrap">
            <Link to="#" className="title text-truncate">
              {car && car.car_name ? car.car_name : null}
            </Link>
            <div className="price mb-2">${car && car.price ? car.price : null}</div>
            <div className="row">
              <Link
                to={`/product/${car && car._id ? car._id : null}/edit`}
                className="btn btn-sm btn-outline-success p-2 pb-3 col-md-6"
              >
                <i className="fas fa-pen"></i>
              </Link>
              {/*
              <Link
                to="#"
                className="btn btn-sm btn-outline-danger p-2 pb-3 col-md-6"
              >
                <i className="fas fa-trash-alt"></i>
              </Link>
  */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
