import { CAR_CREATE_FAIL, CAR_CREATE_REQUEST, CAR_CREATE_RESET, CAR_CREATE_SUCCESS,
    CAR_EDIT_FAIL, CAR_EDIT_REQUEST, CAR_EDIT_SUCCESS,
    CAR_LIST_FAIL, CAR_LIST_REQUEST, CAR_LIST_SUCCESS, CAR_UPDATE_FAIL, CAR_UPDATE_REQUEST, CAR_UPDATE_RESET, CAR_UPDATE_SUCCESS } from "../Constants/CarConstants";

// ALL CARS
export const carListReducer = (state = { cars: [] }, action) => {
    switch (action.type) {
      case CAR_LIST_REQUEST:
        return { loading: true, cars:[] };
      case CAR_LIST_SUCCESS:
        return { loading: false, cars: action.payload };
      case CAR_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };


  // CREATE CARS
export const carCreateReducer = (state = { cars: {} }, action) => {
  switch (action.type) {
    case CAR_CREATE_REQUEST:
      return { loading: true };
    case CAR_CREATE_SUCCESS:
      return { loading: false, success: true, cars: action.payload };
    case CAR_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CAR_CREATE_RESET:
      return {}
    default:
      return state;
  }
};

  // EDIT CARS
  export const carsEditReducer = (state = {car: {} }, action) => {
    switch (action.type) {
        case CAR_EDIT_REQUEST:
            return {...state,loading: true };
        case CAR_EDIT_SUCCESS:
            return {loading: false, car: action.payload};
        case CAR_EDIT_FAIL:
            return {loading: false, error: action.payload };
        default:
            return state;
    }
};

  // UPDATE CARS
  export const carUpdateReducer = (state = { cars: {} }, action) => {
    switch (action.type) {
      case CAR_UPDATE_REQUEST:
        return { loading: true };
      case CAR_UPDATE_SUCCESS:
        return { loading: false, success: true, cars: action.payload };
      case CAR_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case CAR_UPDATE_RESET:
        return {cars: {}}
      default:
        return state;
    }
  };