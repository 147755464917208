import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { listLeads } from "../../Redux/Actions/LeadActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import { useDispatch, useSelector } from 'react-redux';



const Orders = () => {

  const dispatch = useDispatch()

  const leadList = useSelector((state) => state.leadList)
  const { loading, error, leads } = leadList 
  useEffect(() => {
    dispatch(listLeads())
  }, [dispatch])

  return (
    
    <table className="table">
      {
            loading ? (<Loading/>) 
            : error ? (<Message variant="alert-danger">{error}</Message>)
            : (
              <>
              <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Email</th>
                <th scope="col">Telemóvel</th>
                <th scope="col">Mensagem</th>
                <th scope="col">Matricula</th>
                <th scope="col">Carro</th>
                <th>Status</th>
                {/*<th scope="col" className="text-end">
                  Action
                </th>*/}
              </tr>
            </thead>
            <tbody>
      
            {leads.map((lead) => (
              <tr>
                <td>
                  <b>{lead.name}</b>
                </td>
                <td>{lead.email}</td>
                <td>{lead.phone}</td>
                <td>
                {lead.message}
                </td>
                <td>{lead.car_registration}</td>
                <td>{lead.car_name}</td>
                <td>
                  <span className="badge btn-success">{lead.status}</span>
                </td>
                {/*<td className="d-flex justify-content-end align-item-center">
                  <Link to={`/order`} className="text-success">
                    <i className="fas fa-eye"></i>
                  </Link>
            </td>*/}
              </tr>
                      ))}
      
            </tbody>
            </>
            )
          }
      
    </table>
  );
};

export default Orders;
