export const CAR_LIST_REQUEST = "CAR_LIST_REQUEST";
export const CAR_LIST_SUCCESS = "CAR_LIST_SUCCESS";
export const CAR_LIST_FAIL = "CAR_LIST_FAIL";


export const CAR_EDIT_REQUEST = "CAR_EDIT_REQUEST";
export const CAR_EDIT_SUCCESS = "CAR_EDIT_SUCCESS";
export const CAR_EDIT_FAIL = "CAR_EDIT_FAIL";

export const CAR_CREATE_REQUEST = "CAR_CREATE_REQUEST";
export const CAR_CREATE_SUCCESS = "CAR_CREATE_SUCCESS";
export const CAR_CREATE_FAIL = "CAR_CREATE_FAIL";
export const CAR_CREATE_RESET = "CAR_CREATE_RESET";

export const CAR_UPDATE_REQUEST = "CAR_UPDATE_REQUEST";
export const CAR_UPDATE_SUCCESS = "CAR_UPDATE_SUCCESS";
export const CAR_UPDATE_FAIL = "CAR_UPDATE_FAIL";
export const CAR_UPDATE_RESET = "CAR_UPDATE_RESET";

